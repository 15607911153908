import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { optionsBuilder } from '../asyncHooks/getRequestInfo';
import { Platforms } from '../types';
import { shouldRefetch } from '../utils';
import { useRequestId } from './useRequestId';

const REFETCH_INTERVAL = 3000;

export const useRequestData = ({
  lastSuccessfullyAccessedPlatform,
}: {
  lastSuccessfullyAccessedPlatform: Platforms | undefined;
}) => {
  const requestId = useRequestId();

  const { data, refetch } = useQuery({
    ...optionsBuilder(requestId),
    select: (data) => ({
      isLinkShareable: data.isLinkShareable,
      requestFrom: data.requestFrom,
      platformAndDocuments: data.platformAndDocuments,
      platformStatus: data.platformStatus,
      downloadReport: data.downloadReport,
    }),
    staleTime: REFETCH_INTERVAL,
  });

  if (!data) throw new Error('Request is required');

  useEffect(() => {
    if (
      shouldRefetch({
        lastAccessedPlatform: lastSuccessfullyAccessedPlatform,
        platformStatuses: data.platformStatus,
        platformAndDocuments: data.platformAndDocuments,
      })
    ) {
      const interval = setInterval(() => {
        void refetch();
      }, REFETCH_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [
    refetch,
    data.platformAndDocuments,
    data.platformStatus,
    lastSuccessfullyAccessedPlatform,
  ]);

  return data;
};
